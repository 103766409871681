import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'agencySelect',
    'agencyProgramSelect',
    'stateSelect',
    'countySelect'
  ]

  initialize () {

  }

  setAgency () {
    const agencyId = this.targets.find('agencySelect').value

    let url = new URL(location.protocol + '//' + location.host + '/agency_programs/filter')
    let params = {
      agency_id: agencyId
    }

    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    fetch(url,
      {
        method: 'get',
        dataType: 'json',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => response.json())
      .then(data => {
          this.updateDropdown(this.targets.find('agencyProgramSelect'), data, 'Agency Program')
        }
      )
  }

  setAgencyProgram () {
    // does this limit anything?
  }

  setState () {
    const stateId = this.targets.find('stateSelect').value

    let url = new URL(location.protocol + '//' + location.host + '/counties/filter')
    let params = {
      state_id: stateId
    }

    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    fetch(url,
      {
        method: 'get',
        dataType: 'json',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => response.json())
      .then(data => {
          this.updateDropdown(this.targets.find('countySelect'), data, 'County')
        }
      )
  }

  filterAgency () {
    // search by substring?
  }

  filterAgencyProgram () {
    // search by substring?
  }

  updateDropdown (target, data, defaultOption) {
    target.innerHTML = ''

    const blankOption = document.createElement('option')
    blankOption.innerHTML = defaultOption
    blankOption.value = ''
    target.appendChild(blankOption)

    if (data.length > 0) {
      data.forEach((lineItem) => {
        const option = document.createElement('option')
        option.value = lineItem.id
        option.innerHTML = lineItem.name
        target.appendChild(option)
      })
    }
  }

}