import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'dataRequestLineCount',
    'dataRequestLineCountyId',
    'dataRequestLineCountResult'
  ]

  getDataRequestLineCount () {
    let dataRequestLineCount = this.dataRequestLineCountTarget.value
    let dataRequestCountyId = this.dataRequestLineCountyIdTarget.value

    let url = new URL(location.protocol + '//' + location.host + '/counts/data_request_line')
    let params = {
      dataRequestLineCount: this.dataRequestLineCountTarget.value,
      dataRequestLineCountyId: this.dataRequestLineCountyIdTarget.value
    }

    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    fetch(url)
      .then(response => response.json())
      .then(r => {
        this.dataRequestLineCountResultTarget.textContent = `${r.record_count} / ${r.backfill_count}`
      })
  }
}
