import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "submitButton"
  ]

  initialize () {
    jQuery('.datacube_export_line_county_id > .select2').select2({ theme: 'bootstrap' })
  }

  reset () {
    this.element.reset()
    this.submitButtonTarget.disabled = false
  }
}
