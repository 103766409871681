import { Controller } from 'stimulus'
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    'agencyProgramSelect',
    'county',
    'countySelect',
    'fulfillmentTerm',
    'fulfillmentTermSelect',
    'fulfillmentTermLimit',
    'fulfillmentTermLimitSelect',
    'subscriptionType',
    'subscriptionTypeSelect',
    'requestType',
    'requestTypeSelect',
    'replacingFor',
    'replacingForSelect',
    'requestedFor',
    'requestedForSelect',
    'changeType',
    'changeTypeSelect'
  ]

  static values = {
    id: String
  }

  initialize () {

  }

  doApprove() {
    if(confirm('Are you sure?')) {
      var id = this.idValue;
  
      var urlString = location.protocol + '//' + location.host + "/subscription_requests/" + id + "/approve";

      var fullUrl = new URL(urlString);

      var today = new Date();
      var dateString = this.formatStatusDate(today);

      Rails.ajax({
        url: fullUrl,
        type: "PUT",
        success: function(response) {
          $('#statused-at-' + id).html(dateString);
          $('#status-' + id).html("approved");
        }
      });
    }
  }

  doDeny() {
    if(confirm('Are you sure?')) {
      var id = this.idValue;
      var urlString = location.protocol + '//' + location.host + "/subscription_requests/" + id + "/deny";

      var fullUrl = new URL(urlString);

      var today = new Date();
      var dateString = this.formatStatusDate(today);

      Rails.ajax({
        url: fullUrl,
        type: "PUT",
        success: function(response) {
          $('#statused-at-' + id).html(dateString);
          $('#status-' + id).html("denied");
        }
      });
    }
  }

  formatStatusDate(date) {
    // I cannot believe this has to be a thing

    return date.getFullYear().toString() + '-' + (("0" + (date.getMonth() + 1)).slice(-2)).toString() + '-' + (("0" + date.getDate()).slice(-2)).toString();
  }

  setAgencyProgram () {
    this.countyTarget.style.display = 'block'
    if(this.agencyProgramSelectTarget.value === 'c818e705-ced8-4684-aabf-5ef12899e6a6' || this.agencyProgramSelectTarget.value === '6ba1e6f8-0637-4078-9e66-bef6ea8ab988') {
      this.fulfillmentTermLimitTarget.style.display = 'block'
    } else {
      jQuery(this.fulfillmentTermLimitSelectTarget).val(null).trigger("change")
      this.fulfillmentTermLimitTarget.style.display = 'none'
    }
    jQuery(this.countySelectTarget).select2('destroy').select2({ theme: 'bootstrap' })
  }

  setCounty () {
    this.loadCounty()
      .then(r => {
        this.subscriptionTypeSelectTarget.value = `${r}`
      })

    this.subscriptionTypeTarget.style.display = 'block'
    this.requestTypeTarget.style.display = 'block'
  }

  setFulfillmentTermLimit () {

  }

  setRequestType () {
    if (this.requestTypeSelectTarget.value == 'change' || this.requestTypeSelectTarget.value == 'remove') {
      this.loadSubscribers()
        .then(r => {
          this.replacingForSelectTarget.innerHTML = '<option value=\'\'></option>'
          for (let sub of r) {
            this.replacingForSelectTarget.innerHTML += '<option value="' + sub.id + '">' + sub.user_name + '</option>'
          }
        })
      this.replacingForTarget.style.display = 'block'
    }

    if(this.requestTypeSelectTarget.value == 'add' || this.requestTypeSelectTarget.value == 'remove') {
      this.changeTypeTarget.style.display = 'none'
    }

    if (this.requestTypeSelectTarget.value == 'add') {
      this.replacingForTarget.style.display = 'none'
      this.replacingForSelectTarget.innerHTML = '<option value=\'\'></option>'
    }

    this.requestedForTarget.style.display = 'block'
    jQuery(this.requestedForSelectTarget).select2('destroy').select2({ theme: 'bootstrap' })
  }

  setReplacingFor() {
    this.loadSubscription()
      .then(r => {
        if(r.status == 'sunset' && r.style == 'exclusive' && this.requestTypeSelectTarget.value == 'change') {
          this.changeTypeTarget.style.display = 'block'
        } else {
          this.changeTypeTarget.style.display = 'none'
          this.changeTypeSelect.value = ''
        }
      })
  }

  loadSubscription() {
    let url = new URL(location.protocol + '//' + location.host + '/api/subscription')
    let params = {
      subscription_id: this.replacingForSelectTarget.value
    }

    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url)
      .then(response => response.json())
  }

  loadCounty () {
    let url = new URL(location.protocol + '//' + location.host + '/api/county_subscription_type')
    let params = {
      agency_program_id: this.agencyProgramSelectTarget.value,
      county_id: this.countySelectTarget.value
    }

    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url)
      .then(response => response.json())
  }

  loadSubscribers () {
    let url = new URL(location.protocol + '//' + location.host + '/api/county_subscribers')
    let params = {
      agency_program_id: this.agencyProgramSelectTarget.value,
      county_id: this.countySelectTarget.value
    }

    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url)
      .then(response => response.json())
  }
}
