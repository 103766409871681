// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// ***** START: ADDED BY KisoThemes *****
window.$ = window.jQuery = require('jquery')
// ***** END: ADDED BY KisoThemes *****

//require('@rails/ujs').start()
require('select2')
require('@nathanvda/cocoon')
// ***** START: ADDED BY KisoThemes *****
require('bootstrap')
require('metismenu/dist/metisMenu')
require('jquery-slimscroll')
require('bootstrap-switch')
require('fastclick')
require('object-fit-images')
require('jasny-bootstrap')
require('jvectormap')
// ***** END: ADDED BY KisoThemes *****

require('kiso_themes')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "channels"
import "controllers"
import "@hotwired/turbo-rails"
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"

require('flatpickr/dist/flatpickr.css')

Rails.start()
ActiveStorage.start()

const onDynamicLoad = () => {
  let select2 = $('.select2')

  select2.select2({
    theme: 'bootstrap',
  })

  select2.on('select2:select', function (e) {
    let event = new Event('change', { bubbles: true })
    e.target.dispatchEvent(event)
  })

  $('body').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
    $('.select2').select2({
      theme: 'bootstrap',
    })
  })
}

document.addEventListener("turbo:load", onDynamicLoad)

$(window).on('load', function() {

});
