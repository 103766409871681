import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'campaignInfo',
    'campaignSetSelect',
    'campaignId',
    'campaignSet',
    'dataProfileMeta',
    'dataProfileOverride',
    'dataProfileOverrideEarmarkedTag',
    'dataProfileOverrideInfo',
    'dataProfileOverrideSet',
    'dataProfileOverrideSetSelect',
    'dataProfileInfo',
    'dataProfileName',
    'dataProfileDistribution',
    'dataProfileSegmentation',
    'dataProfileRangeType',
    'dataProfileRangeValues',
    'dataProfileEarmarkedTag'
  ]

  initialize () {
    if (this.campaignSetSelectTarget.value != '') {
      this.setCampaign()
    }

    if (this.dataProfileOverrideSetSelectTarget.value != '') {
      jQuery('#override-tab').trigger('click')
      this.setDataProfileOverride()
    }
  }

  setCampaign () {
    this.campaignIdTarget.textContent =
      `${this.campaignSetSelectTarget.options[this.campaignSetSelectTarget.selectedIndex].innerHTML}`

    this.campaignInfoTarget.style.display = 'block'
    this.campaignSetTarget.style.display = 'none'

    this.loadDataProfile(this.campaignSetSelectTarget.value)
      .then(r => {
        this.dataProfileNameTarget.textContent = `${r.name}`
        this.dataProfileDistributionTarget.textContent = `${r.distribution}`
        this.dataProfileSegmentationTarget.textContent = `${r.segmentation}`
        this.dataProfileRangeTypeTarget.textContent = `${r.range_type}`
        this.dataProfileRangeValuesTarget.textContent = `${r.range_min} - ${r.range_max}`
      })

    this.dataProfileMetaTarget.style.display = 'block'
  }

  editCampaign () {
    this.campaignInfoTarget.style.display = 'none'
    this.campaignSetTarget.style.display = 'block'
  }

  setDataProfileOverride () {
    this.dataProfileOverrideTarget.textContent =
      `${this.dataProfileOverrideSetSelectTarget.options[this.dataProfileOverrideSetSelectTarget.selectedIndex].innerHTML}`

    this.dataProfileOverrideInfoTarget.style.display = 'block'
    this.dataProfileOverrideSetTarget.style.display = 'none'

    if (this.dataProfileOverrideSetSelectTarget.value == 'earmarked') {
      this.dataProfileOverrideEarmarkedTagTarget.style.display = 'block'
    } else {
      this.dataProfileOverrideEarmarkedTagTarget.style.display = 'none'
      this.dataProfileOverrideEarmarkedTagTarget.value = ''
    }

  }

  editDataProfileOverride () {
    this.dataProfileOverrideInfoTarget.style.display = 'none'
    this.dataProfileOverrideSetTarget.style.display = 'block'
  }

  overrideDataProfile () {
    //TODO
  }

  defaultDataProfile () {
    this.dataProfileOverrideSetSelectTarget.value = ''
    this.dataProfileOverrideEarmarkedTagTarget.value = ''
    this.dataProfileOverrideTarget.textContent = ' '
    this.dataProfileOverrideEarmarkedTagTarget.style.display = 'none'
    this.dataProfileOverrideSetTarget.style.display = 'block'
    this.dataProfileOverrideInfoTarget.style.display = 'none'
  }

  loadDataProfile (campaignId) {
    let campaignUrl = '/campaigns/' + campaignId + '.json'

    return fetch(campaignUrl)
      .then(response => response.json())
      .then(campaign => {
        let agencyProgramUrl = '/agency_programs/' + campaign.agency_program_id + '.json'
        return fetch(agencyProgramUrl)
      })
      .then(response => response.json())
      .then(agencyProgram => {
        let programUrl = '/programs/' + agencyProgram.program_id + '.json'
        return fetch(programUrl)
      })
      .then(response => response.json())
      .then(program => {
        let dataProfileUrl = '/datacube/profiles/' + program.datacube_profile_id + '.json'
        return fetch(dataProfileUrl)
      })
      .then(response => response.json())
  }
}
