import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'form',
    'stateSelect',
    'countiesSelected',
    'countiesSet',
    'countySelection',
    'zipsSet',
    'zipInput',
    'phoneField',
    'incAField',
    'incBField',
    'incCField',
    'incDField',
    'incEField',
    'incFField',
    'incGField',
    'incHField',
    'incIField',
    'incJField',
    'incKField',
    'incLField',
    'incMField',
    'incNField',
    'incOField',
    'ageField',
    'dobField',
    'omitPOField',
    'hYField',
    'dtSField',
    'dtCField',
    'dtAField',
    'dtTField',
    'dtPField',
    'dtBField',
    'ffField',
    'emailSet',
    'emailField',
    'submitButton'
  ]

  static classes = [
    'warning'
  ]

  initialize () {
  }

  setCounties () {
    var statesTargets = this.stateSelectTargets

    var states = []

    $(statesTargets).each(function () {
      if ($(this).is(':checked')) {
        states.push($(this).val())
      }
    })

    let url = new URL(location.protocol + '//' + location.host + '/count/counties')
    let params = {
      states: states
    }

    $(this.countiesSetTarget).empty()

    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    fetch(url)
      .then((r) => r.text())
      .then((html) => {
        const fragment = document
          .createRange()
          .createContextualFragment(html)

        this.countiesSetTarget.appendChild(fragment)
      })
  }

  toggleCounties () {
    $('#counties').show()
    $('#zips').hide()

    $('#details').show()

    this.setCounties()

    $('#counties-selected-toggle').prop('value', true)
  }

  toggleZips () {
    $('#counties').hide()
    $('#zips').show()

    $('#details').show()

    $('#counties-selected-toggle').prop('value', false)
  }

  clearDetails () {
    $('#details input[type=text]').val('')
    $('#details input[type=checkbox]').prop('checked', false)
  }

  populateSCAT () {
    var dwellingTargets = [
      this.dtSFieldTarget,
      this.dtCFieldTarget,
      this.dtAFieldTarget,
      this.dtTFieldTarget
    ]

    $.each(dwellingTargets, function (i, val) {
      val.checked = true
    })
  }

  populateML () {
    this.clearDetails()

    this.ageFieldTarget.value = '62-80'

    var incomeTargets = [
      this.incAFieldTarget,
      this.incBFieldTarget,
      this.incCFieldTarget,
      this.incDFieldTarget
    ]

    $.each(incomeTargets, function (i, val) {
      val.checked = true
    })

    this.populateSCAT()

    this.ffFieldTarget.checked = true
  }

  populateMT () {
    this.clearDetails()

    var today = new Date()

    var today_date = today.getDate()
    var today_month = today.getMonth()
    var today_year = today.getFullYear()

    var push = 0

    if (today_date >= 15) {
      push = 1
    }

    var lower = new Date(today_year - 65, today_month + 5 + push, today_date)
    var upper = new Date(today_year - 65, today_month + 7 + push, today_date)

    var dateRange = lower.getFullYear().toString() + ('0' + ((lower.getMonth() + 1).toString())).slice(-2) + '-' + upper.getFullYear().toString() + ('0' + ((upper.getMonth() + 1).toString())).slice(-2)

    this.dobFieldTarget.value = dateRange

    var incomeTargets = [
      this.incAFieldTarget,
      this.incBFieldTarget,
      this.incCFieldTarget,
      this.incDFieldTarget,
      this.incEFieldTarget,
      this.incFFieldTarget,
      this.incGFieldTarget,
      this.incHFieldTarget
    ]

    $.each(incomeTargets, function (i, val) {
      val.checked = true
    })

    this.populateSCAT()
  }

  populateMA () {
    this.clearDetails()

    this.ageFieldTarget.value = '60-80'

    var incomeTargets = [
      this.incAFieldTarget,
      this.incBFieldTarget,
      this.incCFieldTarget,
      this.incDFieldTarget
    ]

    $.each(incomeTargets, function (i, val) {
      val.checked = true
    })

    this.populateSCAT()

    this.ffFieldTarget.checked = true
  }

  populateFE () {
    this.clearDetails()

    this.ageFieldTarget.value = '50-80'

    var incomeTargets = [
      this.incBFieldTarget,
      this.incCFieldTarget,
      this.incDFieldTarget,
      this.incEFieldTarget
    ]

    $.each(incomeTargets, function (i, val) {
      val.checked = true
    })

    this.populateSCAT()

    this.ffFieldTarget.checked = true
  }

  qualifySubmit () {
    var blankEmail = (this.emailFieldTarget.value == '')
    var unfulfilledCounties = (this.countiesSelectedTarget.value == 'true' && this.countySelectionTargets.length > 0 && this.countySelectionTargets.filter(checkbox => checkbox.checked).length == 0)
    var unfulfilledZips = (this.countiesSelectedTarget.value == 'false' && this.zipInputTarget.value == '')

    if (blankEmail || unfulfilledCounties || unfulfilledZips) {
      this.countiesSetTarget.classList.remove(this.warningClass)
      this.zipsSetTarget.classList.remove(this.warningClass)
      this.emailSetTarget.classList.remove(this.warningClass)

      if (blankEmail) {
        this.emailSetTarget.classList.add(this.warningClass)
      }

      if (unfulfilledCounties) {
        this.countiesSetTarget.classList.add(this.warningClass)
      }

      if (unfulfilledZips) {
        this.zipsSetTarget.classList.add(this.warningClass)
      }
    } else {
      this.submitButtonTarget.disabled = true
      this.formTarget.submit()
    }
  }

}